import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';

import { handleExternalRedirection } from 'eficia/utilities/redirections';

import { AppFooter } from './Footer.style';

export default function Footer() {
  const { t } = useTranslation();

  const withoutVisibleSidebar = useMediaQuery((theme) => theme.breakpoints.down('lg'));

  function handleRedirection() {
    handleExternalRedirection('https://eficia.com/');
  }

  return (
    <AppFooter withoutVisibleSidebar={withoutVisibleSidebar} data-testid="footer">
      <Link onClick={handleRedirection}>
        <span>©{new Date().getFullYear()} Eficia</span>
      </Link>
      <span>|</span>
      <a
        target="_blank"
        href="https://eficiahelp.zendesk.com/hc/fr/articles/6856842797341/"
      >
        <span>{t('footer.terms_of_use')}</span>
      </a>

      <span>|</span>
      <a target="_blank" href="https://eficiahelp.zendesk.com/hc/fr">
        <span>{t('footer.help_center')}</span>
      </a>
      <span>|</span>
      <a
        target="_blank"
        href="https://eficiahelp.zendesk.com/hc/fr/articles/6539998577949-FAQ"
      >
        <span>{t('footer.frequently_asked_question')}</span>
      </a>
    </AppFooter>
  );
}
